@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
.App {
  text-align: center;
  background-color: black;
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  display: flex;
}
.Donate-now {
  margin-top: 30px;
}
.App img {
  height: 100vh;
}
.App-payment-details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App-payment-details select {
  font-size: 17px;
}
.App-payment-details input[type="text"],
.App-payment-details input[type="number"],
.App-payment-details input[type="email"] {
  padding-left: 4px;
  width: 300px;
  height: 35px;
  font-size: 15px;
  border: 1px solid black;
  font-family: "Poppins", sans-serif;
}
.App-payment-details label {
  display: flex;
  color: white;
  font-size: 20px;
  justify-content: space-between;
  margin-top: 10px;
  width: 700px;
}
.App-payment-details button {
  height: 40px;
  width: 280px;
  background-color: #3c7ec8;
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 15px;
  transition: background-color 0.3s;
  border: none;
}
.App-payment-details button:hover {
  background-color: #6e6968;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
